import React from "react";
import { Col, Container, Row } from "react-bootstrap";

/** images */
import TeckatLogo from "../../../Assets/img/DrDKMishra/icons/teckat-logo.png";

const FooterMain = () => {
  return (
    <div>
      {/* Section Start */}
      <section className="pt-[80px] md:pt-[40px] bg-[#f0f9f9]">
        <Container>
          <Row className="row-cols-1 row-cols-lg-4 row-cols-sm-2">
            <Col className="text-center md:mb-[40px]">
              <i className="feather-map-pin text-[28px] text-basecolor mb-[30px] sm:mb-[10px] block"></i>
              <div className="text-darkgray text-[15px] font-medium font-serif mb-[10px]">
                Clinic location
              </div>
              <p className="w-[100%] lg:w-full md:w-[60%] sm:w-[75%] mx-auto">
                39, 2nd Floor, NewBaradwari Road, Telephone Exchange Road Sakchi
                Jamshedpur, Jharkhand 831001 India
              </p>
            </Col>
            <Col className="text-center md:mb-[40px]">
              <i className="feather-phone-call text-[28px] text-basecolor mb-[30px] sm:mb-[10px] block"></i>
              <div className="text-darkgray text-[15px] font-medium font-serif mb-[10px]">
                For appointment
              </div>
              <p className="w-[100%] lg:w-full mx-auto">
                Phone:{" "}
                <a
                  href="tel:+91 87099 40213"
                  className="text-[#00b8b8] hover:text-[#1D2C5A]"
                >
                  +91 87099 40213
                </a>{" "}
                <br />
                Emergency:{" "}
                <a
                  href="tel:+91 94311 72711"
                  className="text-[#00b8b8] hover:text-[#1D2C5A]"
                >
                  +91 94311 72711
                </a>
              </p>
            </Col>

            <Col className="text-center xs:mb-[40px]">
              <i className="feather-mail text-[28px] text-basecolor mb-[30px] sm:mb-[10px] block"></i>
              <div className="text-darkgray text-[15px] font-medium font-serif mb-[10px]">
                Patient services
              </div>
              <p className="w-[90%] lg:w-full mx-auto">
                <a
                  aria-label="mail"
                  href="drdilip.mishra123@gmail.com"
                  className="text-[#00b8b8] hover:text-[#1D2C5A]"
                >
                  drdilip.mishra123@gmail.com
                </a>
                {/* <br />
                <a
                  aria-label="mail"
                  href="mailto:drdilip.mishra123@gmail.com"
                  className="text-[#00b8b8] hover:text-[#1D2C5A] "
                >
                  drdilip.mishra123@gmail.com
                </a> */}
              </p>
            </Col>
            <Col className="text-center xs:mb-[40px]">
              <i className="feather-clock text-[28px] text-basecolor text-pink mb-[30px] sm:mb-[10px] block"></i>
              <div className="text-darkgray text-[15px] font-medium font-serif mb-[10px]">
                Available All Days
              </div>
              <p className="w-[90%] lg:w-full md:w-[60%] sm:w-[75%] mx-auto xs:w-full">
                10 am to 8 pm
              </p>
            </Col>
          </Row>
          <Row className="items-center">
            <Col className="col-12 border-t border-[#0000001a] mt-[80px] md:mt-[40px] xs:m-0"></Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Footer Start */}
      <footer className="bg-[#f0f9f9] py-[50px]">
        <Container>
          <Row className="items-center">
            <Col className="col-12 text-center ">
              <div className="footer_copyright text-center">
                <div>
                  © 2023 Dr. D.K. Mishra | All Rights Reserved.
                  <div className="developed_By flex justify-center ">
                    Developed By
                    <a
                      aria-label="themezaa"
                      href="https://teckat.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="flex underline underline-offset-4 text-[#00b8b8] hover:text-[#1D2C5A] "
                    >
                      <img
                        src={TeckatLogo}
                        alt=""
                        className="w-auto h-10 mx-1"
                      />
                      Teckat.com
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      {/* Footer End */}
    </div>
  );
};

export default FooterMain;
